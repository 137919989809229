@import '@sass';

.faq {
  display: flex;
  align-items: center;
  padding-right: 80px;

  @include mobile {
    margin-top: 16px;
    padding-right: 0px;
  }
}

.li {
  float: left;
  padding-left: 25px;

  a:hover {
    color: $color-white;
    transition: color 0.35s;
  }

  @include mobile {
    padding-left: 0px;
    padding-right: 16px;
    padding-bottom: 8px;
  }
}

.link {
  color: #b2b2b2;
  font-size: 1.4rem;
}
