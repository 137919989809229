.login {
  display: table;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: calc(var(--vh100) - 16rem);
}

.login-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.login-inner {
  margin-left: auto;
  margin-right: auto;
  width: 45.4rem;
}

.login-title {
  width: 45.4rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  color: #ffffff;
  text-align: center;
}
