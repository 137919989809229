@import '@sass';

.container {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  background: rgb(2, 41, 59);
  background: linear-gradient(
    90deg,
    rgba(2, 41, 59, 1) 0%,
    rgba(1, 14, 29, 1) 50%,
    rgba(1, 6, 20, 1) 100%
  );

  font-family: BigerOver;
  font-size: 1.6rem;

  @include mobile {
    display: none;
    height: 32px;
    font-size: 1.4rem;
  }
}

.sticky {
  position: fixed;
  top: 72px;
  z-index: $zindex-fixed;
}

.tab {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid $color-blue-electric;

  @include mobile {
    width: 100%;
  }
}

.icon {
  width: 42px;
  height: 42px;

  @include mobile {
    width: 24px;
    height: 24px;
    font-size: 1.4rem;
  }
}

.text {
  margin-left: 8px;
}
