@import '@sass';

.container {
  width: 100%;
  margin-top: 24px;

  .info {
    width: 100%;

    .info-title {
      font-family: Odibee;
      font-size: 3.2rem;
      line-height: 3.5rem;
    }

    .info-content {
      display: flex;
      gap: 100px;
      padding: 16px 20px;
      border: 2px solid #01dbfe;
      border-radius: 4px;
      background: linear-gradient(
        105.09deg,
        rgba(1, 219, 254, 0.2) -5.77%,
        rgba(9, 44, 76, 0) 105.57%
      );

      @include mobile {
        flex-direction: column;
        gap: 20px;
      }

      .item {
        display: flex;
        flex-direction: column;
      }

      .info-content-title {
        font-family: Montserrat;
        font-size: 1.2rem;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .info-content-text {
        padding-top: 12px;
        font-family: Montserrat;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-blue-electric;
      }

      .copy-icon {
        padding-left: 8px;

        img {
          height: 24px;

          @include mobile {
            height: 12px;
          }
        }
      }
    }
  }
}
