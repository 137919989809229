.container {
  display: inline-flex;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;

  &.disabled {
    pointer-events: none;
    filter: grayscale;
    opacity: 0.1;
  }

  &.black {
    filter: inherit;
  }

  &.gray {
    filter: invert(78%) sepia(0%) saturate(0%) hue-rotate(204deg) brightness(93%) contrast(89%);
  }

  &.green {
    filter: invert(90%) sepia(7%) saturate(1465%) hue-rotate(82deg) brightness(89%) contrast(92%);
  }

  &.white {
    filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
  }

  &.flipX {
    transform: rotateX(180deg);
  }

  &.flipY {
    transform: rotateY(180deg);
  }
}
