@import '@sass';

.container {
  .show-list-button {
    margin-left: 1.5rem;
    width: 4.3rem;
    height: 3.7rem;
    background: #373943;
    border-color: #373943;
    border-width: 0rem;
    border-style: solid;
    border-radius: 0.6rem 0rem 0rem 0.6rem;
    outline: none;
    border-style: outset;
    background-image: url('./listButtonIcon.png');
    background-size: 30%;
    background-position: center center;
    background-repeat: no-repeat;

    &.selected {
      background: #0f2433;
      border-color: #0f2433;
      background-image: url('./listButtonIcon.png');
      background-size: 30%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .show-rows-button {
    width: 4.4rem;
    height: 3.7rem;
    background: #373943;
    border-color: #373943;
    border-width: 0rem;
    border-style: solid;
    border-radius: 0rem 0.6rem 0.6rem 0rem;
    outline: none;
    border-style: outset;
    background-image: url('./rowButtonIcon.png');
    background-size: 30%;
    background-position: center center;
    background-repeat: no-repeat;

    &.selected {
      background: #0f2433;
      border-color: #0f2433;
      background-image: url('./rowButtonIcon.png');
      background-size: 30%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
