@import '@sass';

.nav-desktop {
  @include noDesktop {
    display: none;
  }
}

.li {
  margin-top: 28px;
  float: left;
  padding-left: 25px;
  font-size: 1.2rem;

  :global {
    .active {
      font-family: BigerOver;
      text-decoration-color: #01dbfe;
      text-decoration-line: underline;
      text-decoration-thickness: 0.3rem;
      text-underline-offset: 0.5rem;
    }
  }
}
