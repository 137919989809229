@import '@sass';

.toggle {
  height: 30px;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  padding: 0 10px;
  background: $color-gray;
  border: 1px solid $color-gray-dark;
  border-radius: 4px;

  &.active {
    background: $color-blue;
    border: 1px solid $color-blue-electric !important;
  }
}

.icon {
  height: 24px;
}

.text {
  font-size: 12px;
  text-transform: capitalize;
}
