@import '@sass';

.menu {
  width: 100%;
  height: $header-height-desktop;
  display: flex;
  position: fixed;
  top: 0;
  z-index: $zindex-nav;
  justify-content: center;
  background: #01031166;
  font-family: BigerOver;
  font-size: 1.2rem;

  @include mobile {
    height: $header-height-mobile;
    background: transparent;
  }
}
