@font-face {
  font-family: 'BigerOver';
  src: url('../../fonts/bBigerOver.eot'); /* IE9 Compat Modes */
  src: local('Biger Over'), local('bBigerOver'),
    url('../../fonts/bBigerOver.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/bBigerOver.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/bBigerOver.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/bBigerOver.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/bBigerOver.svg#bBigerOver') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../../fonts/Montserrat-Regular.woff') format('woff'),
    url('../../fonts/Montserrat-Regular.ttf') format('truetype'),
    url('../../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Odibee';
  src: url('../../fonts/OdibeeSans-Regular.eot');
  src: local('Odibee Sans Regular'), local('OdibeeSans-Regular'),
    url('../../fonts/OdibeeSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/OdibeeSans-Regular.woff2') format('woff2'),
    url('../../fonts/OdibeeSans-Regular.woff') format('woff'),
    url('../../fonts/OdibeeSans-Regular.ttf') format('truetype'),
    url('../../fonts/OdibeeSans-Regular.svg#OdibeeSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
