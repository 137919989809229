@import '@sass';

.copyright {
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;

    @include mobile {
      display: none;
    }
  }
}

.text {
  margin-left: 20px;
  font-size: 1.4rem;
  color: #b2b2b2;

  @include under('us') {
    display: none;
  }
}
