@import '@sass';

main.activity {
  min-height: calc(92vh - 160px);
  box-sizing: border-box;
  padding: 80px 60px 0 60px;

  h1 {
    font-size: 3.2rem;
    font-family: 'BigerOver';
    text-transform: uppercase;
    color: $color-gray-light;
  }

  @include noDesktop {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.activities {
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 44px;
  border: 2px solid #01dbfe;
  border-radius: 4px;
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
  font-family: 'Montserrat';

  & div:first-of-type {
    margin-top: 0rem;
  }

  @include mobile {
    padding: 16px;
  }
}

.date {
  margin-top: 80px;
  margin-bottom: 24px;
  font-family: 'Odibee';
  font-size: 4rem;

  @include mobile {
    margin-top: 32px;
    font-size: 2.4rem;
  }
}

.movement {
  display: flex;
  padding-bottom: 40px;
}

.hour {
  width: 56px;
  position: relative;
  margin-right: 40px;
  font-size: 2rem;

  &:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 28px;
    left: 50%;
    background-color: #01dbfe;
  }

  &::last-child {
    padding-bottom: 0px;
  }

  @include mobile {
    font-size: 1.6rem;
  }
}

.url {
  display: flex;
  align-items: center;
  margin-top: 12px;

  a {
    font-size: 1.4rem;
    color: #01dbfe;
  }

  img {
    width: 16px;
    height: 16px;
    margin-left: 12px;
  }
}

.message {
  text-transform: uppercase;
  font-size: 2rem;

  u {
    color: #01dbfe;
  }

  @include mobile {
    font-size: 1.2rem;
  }
}
