@import '@sass';

.container {
  padding: $padding-mobile;
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);

  @include noDesktop {
    width: 100%;
    position: fixed;
    top: $header-height-desktop;
    left: 0;
    z-index: $zindex-fixed+2;
    background: $color-background;
    border: 1px solid $color-blue-electric;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  @include mobile {
    top: $header-height-mobile;
  }
}

.content {
  @include noDesktop {
    height: calc(var(--vh100) - #{$header-height-desktop});
  }

  @include mobile {
    height: calc(var(--vh100) - #{$header-height-mobile});
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.reset {
  text-decoration: underline;
  font-size: 1.2rem;

  &:hover {
    color: $color-blue-electric;
    transition: color 0.35s;
  }
}

.close {
  img {
    width: 20px;
    height: 20px;
    filter: invert(98%) sepia(1%) saturate(355%) hue-rotate(272deg) brightness(122%) contrast(100%);
  }

  @include desktop {
    visibility: hidden;
  }
}

.icon {
  position: fixed;
  bottom: 12px;
  left: 0;
  padding: 8px;
  z-index: $zindex-fixed + 1;
  display: none;
  place-content: center;
  align-items: center;
  background: $color-blue-dark;
  border: 1px solid $color-blue-electric;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left-width: 0;

  img {
    width: 18px;
    height: 18px;
    filter: invert(98%) sepia(1%) saturate(355%) hue-rotate(272deg) brightness(122%) contrast(100%);
  }

  @include noDesktop {
    display: flex;
  }
}

.counter {
  width: 20px;
  height: 20px;
  display: flex;
  place-content: center;
  align-items: center;
  position: absolute;
  bottom: -11px;
  right: -11px;
  background-color: $color-red;
  outline: 3px solid $color-footer;
  border-radius: 50%;
  font-weight: bold;

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: -1;
    background: $color-blue-electric;
    width: 14px;
    height: 14px;
    border-radius: 100% 0 0 0;
  }
}
