@import '@sass';

.container {
  position: relative;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 3px;
  background-color: #5c5c5c;
}

:global {
  .c-chip-with-action {
    padding-right: 24px;
  }
}

.text {
  font-size: 12px;
}

.action {
  width: 8px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 4px;
  z-index: 1;
  filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);

  &.close {
    background: url(./assets/close.svg) center/auto 6px no-repeat;
  }
}
