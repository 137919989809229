/* CONSTS */

// Breakpoints
// https://gs.statcounter.com/screen-resolution-stats
$screen-breakpoints: (
  'ul': 1920,
  'xl': 1366,
  'lg': 1024,
  'md': 768,
  'sm': 576,
  'xs': 425,
  'us': 375,
);

// Zindex
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-nav: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-tooltip: 1070 !default;
$zindex-spinner: 1080 !default;

// Grid
$gutter: 4px;
$grid-gutter-width: $gutter * 4; // 16px
$grid-gutter-height: $gutter * 7; // 28px
$grid-column-mininum-width: $gutter * 36; // 144px

// Fonts
$font-base-size: 16;
$font-main: Montserrat, Helvetica, Arial, sans-serif;
$font-secondary: BigerOver;

// Colors
$color-black: #000;
$color-gray: #101421;
$color-gray-dark: #373943;
$color-gray-medium: #5f6165;
$color-gray-light: #828282;
$color-gray-lighter: #b2b2b2;
$color-blue-dark: #092c4c;
$color-blue: #19465f;
$color-blue_hover: #1f5978;
$color-blue-light: #1386a9;
$color-blue-electric: #01dbfe;
$color-red: #ff6565;
$color-white: #ffffff;

$color-background: $color-gray;
$color-footer: #000311;

$color-rarity-common: $color-white;
$color-rarity-uncommon: #82d73c;
$color-rarity-rare: #00bfff;
$color-rarity-epic: #cf61ea;
$color-rarity-legendary: #ea8e21;

$color-attack: #fee64c;
$color-defense: #45b1fc;
$color-health: #45fc81;
$color-speed: #ff65d0;

// Paddings
$padding-desktop: 32px;
$padding-mobile: 20px;

// Sizes
$header-height-desktop: 72px;
$header-height-mobile: 60px;

:export {
  minDesktopScreen: #{map-get($screen-breakpoints, 'xl')};
  maxMobileScreen: #{map-get($screen-breakpoints, 'md') - 1};
  color-rarity-common: #{$color-rarity-common};
  color-rarity-uncommon: #{$color-rarity-uncommon};
  color-rarity-rare: #{$color-rarity-rare};
  color-rarity-epic: #{$color-rarity-epic};
  color-rarity-legendary: #{$color-rarity-legendary};
}
