.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
}

.header {
  display: flex;
  width: 100%;
  padding-top: 1.8rem;
}

.title {
  width: 49px;
  height: 15px;
  font-family: BigerOver;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

.reset {
  width: 34px;
  height: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #ffffff;
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.input-values {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
}

.start {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.end {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
}

.separator {
  width: 4px;
  height: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.value {
  width: 80px;
  height: 29px;
  background: #101421;
  border: 1px solid #3a3f50;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Odibee;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
