@import '@sass';

$width: 100%;
$padding: 1rem 2rem;
.content {
  display: flex;
  position: relative;
  width: $width;
  justify-content: center;
  align-items: center;
}

.dropdown {
  display: inline-block;
  position: relative;
  width: $width;
}

.dd-button {
  display: inline-block;
  padding: $padding;
  height: 42px;
  background: #101421;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: 1px solid $color-gray-dark;
}

.dd-button-opened {
  display: inline-block;
  padding: $padding;
  // white-space: nowrap;
  width: $width;
  height: 42px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #101421;
  border: 1px solid #01dbfe;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
}

.dd-button:hover {
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #101421;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: relative;
  top: 100%;
  width: $width;
  border-radius: 4px;
  padding: 0;
  margin: 0 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #101421;
  list-style-type: none;
  max-height: 15rem;
  overflow-y: scroll;
  overflow-y: overlay;
}

.dd-menu::-webkit-scrollbar {
  background: #33323d;
  width: 0.6rem;
  border-radius: 24px;
}

/* background of the scrollbar except button or resizer */

.dd-menu::-webkit-scrollbar-track {
  background-color: #33323d;
}

.dd-menu::-webkit-scrollbar-track:hover {
  background-color: #33323d;
}

/* scrollbar itself */

.dd-menu::-webkit-scrollbar-thumb {
  background: #01dbfe;
  border-radius: 24px;
  height: 55.98px;
  // background-color: #babac0;
  // border-radius: 16px;
  // border: 5px solid #fff
}

// .dd-menu::-webkit-scrollbar-thumb:hover {
//     background-color: #a0a0a5;
//     border: 4px solid #f4f4f4
// }

/* set button(top and bottom of the scrollbar) */

.dd-menu::-webkit-scrollbar-button {
  display: none;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li {
  padding: 10px 0px;
  padding: $padding;
  white-space: nowrap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border-left: 1px solid #01dbfe;
  border-right: 1px solid #01dbfe;
  border-bottom: 1px solid #01dbfe;
}

.dd-menu li:hover {
  color: #ffffff;
  background: linear-gradient(0deg, rgba(1, 219, 254, 0.1), rgba(1, 219, 254, 0.1)), #101421;
}
