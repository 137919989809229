@import '@sass';

.profile {
  display: flex;
}

.main {
  width: 100%;
  padding: 24px;

  @include mobile {
    padding: 24px 0;
  }
}

.title {
  font-family: BigerOver;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 35px;
  color: $color-gray-light;

  width: 100%;
}

.body {
  width: 100%;
}

.columb {
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
  border: 2px solid #01dbfe;
  border-radius: 4px;
}

.column {
  display: flex;
  flex-direction: column;
}

.top {
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
  border: 2px solid #01dbfe;
  border-radius: 4px;
}

.top-left {
  height: 100%;
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
  border: 2px solid #01dbfe;
  border-radius: 4px;
}

.bottom {
  margin-top: 2.2rem;
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
  border: 2px solid #01dbfe;
  border-radius: 4px;
}

.disabled {
  opacity: 0.2;
  filter: grayscale(1);
  pointer-events: none;
}
