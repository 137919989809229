.button-container {
  min-width: 32px;
  height: 32px;
  display: inline-flex;
  place-content: center;
  align-items: center;
  position: relative;
  padding: 0 16px;
  border: 0;
  border-radius: 3px;
  user-select: none;

  :global {
    .text.text-spaced {
      margin-left: 8px;
    }
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
