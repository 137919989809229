@mixin max($breakpoint) {
  @media screen and (max-width: #{map-get($screen-breakpoints, $breakpoint) + px}) {
    @content;
  }
}

@mixin min($breakpoint) {
  @media screen and (min-width: #{map-get($screen-breakpoints, $breakpoint) + px}) {
    @content;
  }
}

@mixin under($breakpoint) {
  @media screen and (max-width: #{map-get($screen-breakpoints, $breakpoint) - 1px}) {
    @content;
  }
}

@mixin over($breakpoint) {
  @media screen and (min-width: #{map-get($screen-breakpoints, $breakpoint) + 1px}) {
    @content;
  }
}

// Custom Width
@mixin cw($width) {
  @media screen and (max-width: #{$width+px}) {
    @content;
  }
}

// Custom Height
@mixin ch($height) {
  @media screen and (max-height: #{$height+px}) {
    @content;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin desktop {
  @include min('xl') {
    @content;
  }
}

@mixin noDesktop {
  @include under('xl') {
    @content;
  }
}

@mixin tablet {
  @include under('xl') {
    @include min('md') {
      @content;
    }
  }
}

@mixin mobile {
  @include under('md') {
    @content;
  }
}
