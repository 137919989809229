.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--vh100);
  width: 100%;
  background: rgba(0, 0, 0, 0.8823529411764706);
  justify-content: center;
  overflow-y: hidden;
  z-index: 9999999999;
}
