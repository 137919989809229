@import '@sass';

.container {
  border: 2px solid;
  border-radius: 8px;
  // background: #00000042;
  // background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
  background: rgba(1, 219, 254, 0.08);

  .top {
    display: flex;
    justify-content: space-between;
    padding: 12px 12px;

    .id {
      font-family: Odibee;
      font-size: 1.6rem;
      color: $color-white;
      opacity: 0.6;
    }
  }

  .body {
    position: relative;
    padding-bottom: 8px;
    text-align: center;

    .family-icon {
      position: absolute;
      top: 0;
      left: 10px;

      img {
        height: 32px;
      }
    }

    .piece-image {
      height: 180px;
      padding: 8px 0;
      filter: drop-shadow(0px 4px 48px rgba(1, 219, 254, 0.2));
    }
  }

  .footer {
    position: relative;
    padding: 12px 12px 8px 12px;
    border-top: 1px solid $color-gray-dark;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: $color-gray;

    .name {
      font-family: Odibee;
      font-size: 2.2rem;
      line-height: 24px;
    }

    .antiquity {
      font-family: Odibee;
      font-size: 1.6rem;
      line-height: 1.6rem;
      opacity: 0.6;
    }

    .price {
      height: 52px;
      display: flex;
      place-content: center;
      align-items: center;
    }

    .edition {
      font-family: Odibee;
      opacity: 0.6;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .stats {
      display: flex;
      text-align: right;
      gap: 4px;
      font-size: 1.4rem;
      font-weight: bold;
      font-family: Odibee;

      .attack {
        color: $color-attack;
      }

      .defense {
        color: $color-defense;
      }

      .health {
        color: $color-health;
      }

      .speed {
        color: $color-speed;
      }
    }
  }
}
