$info: #3498db;
$success: #07bc0c;
$warning: #f1c40f;
$error: #e74c3c;

$toastify-font-family: Odibee;
$background-color: #101421;

.Toastify__toast-body {
    font-family: $toastify-font-family;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    line-height: 1.5;
    color: #ffffff;
}

// Success Toast
.toast-success-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: $background-color !important;
    border: 1px solid $success !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  }
  
  .toast-success-container-after {
    overflow: hidden;
    position: relative;
  }
  
  .toast-success-container-after::after{
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: $success;
  }

  // Info Toast

  .toast-info-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: $background-color !important;
    border: 1px solid $info !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  }
  
  .toast-info-container-after {
    overflow: hidden;
    position: relative;
  }
  
  .toast-info-container-after::after{
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: $info;
  }

// Error Toast

.toast-error-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: $background-color !important;
    border: 1px solid $error !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  }
  
  .toast-error-container-after {
    overflow: hidden;
    position: relative;
  }
  
  .toast-error-container-after::after{
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: $error;
  }

  // Warning Toast
  .toast-warning-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: $background-color !important;
    border: 1px solid $warning !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  }
  
  .toast-warning-container-after {
    overflow: hidden;
    position: relative;
  }
  
  .toast-warning-container-after::after{
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: $warning;
  }