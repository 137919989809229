@import '@sass';

.text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;

  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.input {
  width: 50%;
  height: 54px;

  padding-left: 2.3rem;

  margin-top: 2.9rem;

  background: #101421;
  border: 1px solid #01dbfe;
  box-sizing: border-box;
  border-radius: 4px;

  font-size: 1.6rem;
  line-height: 2rem;

  display: flex;
  align-items: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;

  display: flex;
  align-items: center;

  color: #ffffff;
}

.notice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 1.5rem;

  margin-top: 1rem;
  margin-bottom: 3.9rem;

  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;

  color: $color-gray-light;
}
