@import '@sass';

.container {
  width: 250px;
  height: 100%;

  @include noDesktop {
    width: 100%;
  }

  @include tablet {
    height: 350px;
  }

  @include mobile {
    height: auto;
  }

  &.multiple {
    width: 370px;

    @include noDesktop {
      width: auto;
    }
  }

  .cards-list {
    width: 100%;
    height: 100%;
    position: relative;

    @include noDesktop {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }

    @include tablet {
      gap: 40px;
    }

    @include mobile {
      flex-direction: column;
      place-content: center;
    }

    @include desktop {
      picture {
        &:hover:after {
          content: '';
          width: 80%;
          height: 80%;
          position: absolute;
          top: 10%;
          left: 10%;
          z-index: -1;
          box-shadow: 0 0 50px $color-blue-electric;
        }
      }
    }
  }

  .card {
    height: 110%;
    display: block;
    position: absolute;
    top: -5%;
    left: 0;

    @include noDesktop {
      width: 250px;
      display: block;
      position: relative;
      top: 0;
      align-self: center;
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    &:first-child {
      transform: rotate(-10deg);
      z-index: 1;
      left: 0;

      @include noDesktop {
        transform: none;
      }
    }

    &:nth-child(even) {
      transform: rotate(10deg);
      left: 30%;
      z-index: 0;

      &:hover {
        z-index: 2;
      }

      @include noDesktop {
        transform: none;
        left: auto;
      }
    }
  }
}

.modal {
  width: 100vw;
  height: var(--vh100);
  display: flex;
  place-content: center;
  align-items: center;

  .card {
    img {
      height: 100%;
      object-fit: cover;
    }

    @include landscape() {
      height: 80vh;
    }

    @include portrait() {
      max-width: 50%;
      height: 60vw;
    }
  }
}

:global {
  .modal-cards {
    .c-modal-content {
      min-width: 100%;
      pointer-events: none;
    }
  }
}
