@import '@sass';

.footer {
  width: 100%;
  min-height: 48px;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: $zindex-fixed;
  padding: 0 60px;
  background-color: $color-footer;

  @include mobile {
    padding: 0 40px;
    min-height: 40px;
  }
}

.left {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
  padding-right: 24px;
  border-right: 1px solid #5f6165;

  @include under('xl') {
    border: 0;
    padding-right: 8px;
  }
}

.left-content {
  display: flex;

  @include mobile {
    justify-content: center;
    margin: 0 auto;
  }
}

.right {
  display: flex;

  @include under('xl') {
    display: none;
  }
}
