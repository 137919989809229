@import '@sass';

.nav-mobile {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  :global {
    .toggle {
      user-select: none;
      transition: all 0.6s;

      input {
        width: 32px;
        height: 32px;
        position: absolute;
        top: calc(50% - 16px);
        opacity: 0;
        z-index: 1000;
      }

      span {
        display: block;
        width: 29px;
        height: 2px;
        position: relative;
        margin-bottom: 6px;
        background: #ffffff;
        border-radius: 3px;
        z-index: 999;
        transform-origin: 5px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      }

      span:nth-of-type(3) {
        margin-bottom: 0px;
      }

      input:checked ~ span {
        background: #fff;
      }

      input:checked ~ span:nth-of-type(1) {
        transform: rotate(45deg) translate(0px, 0px);
      }

      input:checked ~ span:nth-of-type(2) {
        opacity: 0;
        transform: scale(0.2, 0.2);
      }

      input:checked ~ span:nth-of-type(3) {
        transform: rotate(-45deg) translate(0px, -3px);
      }
    }

    .links {
      width: 40%;
      min-width: 320px;
      min-height: var(--vh100);
      display: flex;
      align-items: center;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 998;
      background: rgba(0, 0, 0, 1);
      border-right: 1px solid $color-white;
      transform-origin: 0% 0%;
      transform: translate(-100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      overflow: hidden;

      .pages {
        width: 100%;
        display: flex;
        transition: all 0.35s;
      }

      .page {
        height: var(--vh100);
        display: flex;
        flex-direction: column;
        flex: 0 0 100%;

        .list {
          display: flex;
          flex-direction: column;
          flex-grow: 10;
          align-items: center;
          place-content: center;

          @include tablet {
            margin-top: -20vh;
          }
        }

        .more {
          width: 100%;
          background-color: $color-blue-light;

          li {
            padding: 8px 0;
          }

          a {
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-white !important;
          }

          .icon {
            width: 14px;
            height: 14px;
            display: inline-block;
            background: url('/assets/images/shared/angle.svg') center/contain no-repeat;
            transform: rotate(90deg);
            filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%)
              contrast(106%);
            pointer-events: none;
          }
        }
      }
    }

    input:checked ~ .links {
      transform: none;
    }

    .bg {
      content: '';
      width: 100%;
      height: 0%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 997;
      background: rgba(0, 0, 0, 0.7);
      opacity: 0;
    }

    input:checked ~ .bg {
      height: 100%;
      opacity: 1;
      transition: opacity 0.3s;
    }

    ul li {
      min-width: 100px;
      display: block;
      padding: 2vh 0;
    }

    a {
      padding: 0;
      margin: 0;
      color: #fff;
    }
  }

  @include desktop {
    display: none;
  }
}
