@import '@sass';

.container {
  display: flex;
  flex-direction: column;
  place-content: center;

  .content {
    width: 300px;
    max-width: 20vw;
    height: 300px;
    max-height: 20vw;
    position: relative;
    display: flex;

    @include noDesktop {
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
      text-align: center;
    }

    .elipse {
      width: 90%;
      height: 90%;
      position: absolute;
      top: 5%;
      left: 5%;
      background: rgba(0, 0, 0, 0.26);
      border: 4px solid transparent;
      border-radius: 50%;
    }

    .image {
      height: 100%;
      position: absolute;
      filter: drop-shadow(0px 4px 48px rgba(1, 219, 254, 0.2));
    }
  }

  .rarity {
    margin-top: 16px;
    text-align: center;
    font-family: 'BigerOver';
    font-size: 2.4rem;
  }
}
