// .container {
//   position: relative;
//   width: 16rem;
//   padding-top: 3.3rem;
//   padding-left: 2.1rem;
// }
// .button {
//   height: 3rem;
//   background: #19465f;
//   border: 0.1rem solid #01dbfe;
//   box-sizing: border-box;
//   border-radius: 0.4rem;
//   display: flex;
//   flex-direction:row;
//   justify-content: center;
//   align-items: center;
//   padding-left: 1.7rem;
//   padding-right: 1.7rem;
// }
// .content{
//     display:flex;
//     flex-direction: column;
//     width: 100%;
// }
// .icon {
//   width: 2.4rem;
//   height: 2.4rem;
// }
// .text {
//   height: 2.2rem;
//   font-family: Montserrat;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 1.2rem;
//   line-height: 2.2rem;
//   text-transform: capitalize;
//   color: #ffffff;
// }
.container {
}

.content {
  display: flex;
}
