.tag {
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;

  width: 10.7rem;
  height: 3rem;

  background: #19465f;
  border: 0.1rem solid #01dbfe;
  box-sizing: border-box;
  border-radius: 0.4rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  img {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.text {
  width: 70%;
  height: 1.6rem;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;

  color: #ffffff;
}
