@import '@sass';

.container {
  height: 272px;
  position: relative;

  @include mobile {
    height: 132px;
  }
}
