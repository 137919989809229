@import '@sass';

.modal-container {
  padding: 48px $padding-desktop $padding-desktop;
  background: $color-background;
  border: 2px solid $color-blue-electric;
  border-radius: 4px;

  @include mobile {
    width: 100vw;
    height: var(--vh100);
    padding: 48px $padding-mobile $padding-mobile;
  }
}

.modal-container-content {
  @include mobile {
    height: calc(var(--vh100) - 76px);
  }
}
