@import '@sass';

main.marketplace {
  display: flex;
}

.filters {
  width: 25%;

  @include noDesktop {
    width: 0;
  }

  :global {
    @include desktop {
      .c-toggle {
        border-color: $color-gray;
      }
    }
  }
}

.main {
  width: 100%;
  padding: 24px;

  @include mobile {
    padding: 24px 0;
  }
}
