@import '@sass';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(50%, 100%, 50%);
  min-width: 700px;
  height: auto;
  margin: auto;
  //padding: 0 2rem;
  margin-top: 20rem;
  border-radius: 12px;
  flex-direction: column;

  @include mobile {
    width: 100vw;
    min-width: auto;
    margin-top: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.payload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 32px;

  @include mobile {
    padding: 16px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  height: 30%;
  width: 100%;
  margin-bottom: 2rem;

  @include mobile {
    display: block;
  }
}

.modal-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 1.5rem;
  padding: 0 3rem;

  height: 5rem;
  margin: 4rem 2rem 0;

  background: linear-gradient(105.09deg, #3c4553 -5.77%, rgba(9, 44, 76, 0) 105.57%);
  border: 1px solid #01dbfe;
  box-sizing: border-box;
  filter: drop-shadow(0px 1px 4px rgba(255, 255, 255, 0.25));
  border-radius: 4px;

  font-family: Odibee;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 40px;

  display: flex;
  align-items: right;

  color: #01dbfe;
}

.text {
  padding-top: 4.2rem;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;

  @include mobile {
    font-size: 1.2rem;
  }
}

.title {
  padding-top: 5.4rem;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.4rem;

  text-transform: uppercase;

  @include mobile {
    font-size: 1.6rem;
  }
}

.orange-gradient {
  background: #101421;
  border: 2px solid #01dbfe;
  border-radius: 4px;
}
