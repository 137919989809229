@import '@sass';

.rarity-tag {
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  border-radius: 4px;
  border: 1px solid;

  &.common {
    border-color: $color-rarity-common;
    background-color: rgba(255, 255, 255, 0.2);

    .text {
      color: $color-rarity-common;
    }
  }

  &.uncommon {
    border-color: $color-rarity-uncommon;
    background-color: rgba(130, 215, 60, 0.1);

    .text {
      color: $color-rarity-uncommon;
    }
  }

  &.rare {
    border-color: $color-rarity-rare;
    background-color: rgba(0, 191, 255, 0.1);

    .text {
      color: $color-rarity-rare;
    }
  }

  &.epic {
    border-color: $color-rarity-epic;
    background-color: rgba(207, 97, 234, 0.1);

    .text {
      color: $color-rarity-epic;
    }
  }

  &.legendary {
    border-color: $color-rarity-legendary;
    background-color: rgba(234, 142, 33, 0.1);

    .text {
      color: $color-rarity-legendary;
    }
  }
}

.text {
  margin-top: 1px;
  font-family: BigerOver;
  font-size: 1rem;
}
