@import '@sass';

.container {
  width: 24px;
  height: 24px;
  position: relative;
}

.options {
  position: absolute;
  top: 32px;
  right: -48px;

  @include mobile {
    right: 0;
  }
}

.logout {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 36px;
  right: -106px;
  height: 52px;
  width: 235px;
  background: $color-footer;
  border-radius: 4px;
  border: 1px solid #01dbfe;
  box-shadow: 1px 12px 33px rgba(0, 0, 0, 0.5);

  @include mobile {
    right: -12px;
  }
}

.logout:before {
  border: 12px solid rgb(6, 27, 47);
  content: '';
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -22px;
  left: calc(50% - 14px);

  @include mobile {
    left: auto;
    right: 10px;
  }
}

.entry {
  width: 120px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  font-family: BigerOver;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;

  border: 1px solid #01dbfe;

  background: linear-gradient(105.09deg, rgb(10, 90, 103) -5.77%, rgba(9, 44, 76, 1) 105.57%);

  color: #ffffff;
}

.user {
  z-index: 99999999;
}
