.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container {
  // :global {
  input[type='checkbox'] {
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
  }

  .check {
    width: 20px;
    height: 20px;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #19465e;
    background: #101421;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;

    &.active {
      border-color: #01dbfe;
    }
  }

  .label-container {
    font-family: BigerOver;
  }

  .label {
    margin-left: 0.8rem;
  }
  // }
}
