$background-bar-color: grey;
$height-bar: 14px;

.stats {
  width: 100%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.text {
  width: 10%;
  min-width: 44px;
  text-align: left;
  font-family: BigerOver;
  font-size: 1.4rem;
}

.value {
  width: 10%;
  max-width: 44px;
  font-family: BigerOver;
  font-size: 1.4rem;
  display: flex;
  align-items: center;

  padding-left: 1rem;
}

.bar-container {
  height: $height-bar;
  width: 80%;
  max-width: 100%;
  z-index: 0;
  background-color: $background-bar-color;
}

.bar {
  display: flex;
  align-items: center;

  // height: $height-bar;
  z-index: 1;

  background: $background-bar-color;
  border-right: 0.5rem solid transparent;
}
