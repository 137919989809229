.modal {
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    height: var(--vh100);
    top: 0;
    left: 0;
  }
}

.modal-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-close {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  background: url(./assets/close.svg) center/auto 100% no-repeat;
  filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
}

.modal-header {
  margin-right: 20px;
}

.modal-body {
  font-size: inherit;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.modal-content {
  width: 70%;
  max-width: 90%;
  min-width: 300px;
  position: relative;
  padding: 16px 24px;
  background-color: #282c34;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    max-width: inherit;
  }
}

:global {
  .noscroll {
    overflow: hidden !important;
  }
}
