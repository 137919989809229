.input-container {
  width: 100%;

  input {
    width: 100%;
    height: 5.4rem;

    padding-left: 2.3rem;

    background: #101421;
    border: 0.1rem solid #01dbfe;
    box-sizing: border-box;
    border-radius: 0.4rem;

    font-size: 1.6rem;
    line-height: 2rem;

    display: flex;
    align-items: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;

    display: flex;
    align-items: center;

    color: #ffffff;
  }
}
