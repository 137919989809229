.container {
  width: 100%;
  position: relative;
  margin-top: 28px;
}

.filter-collapsed {
  max-height: 32px;
  overflow: hidden;

  .filter-collapse-button {
    transform: rotate(180deg);
  }
}

.filter-title {
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e8eb;
  font-family: BigerOver;
  font-size: 1.4rem;
}

.filter-collapse-button {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 8px;
  right: 4px;
  background: url('/assets/images/shared/angle.svg') center/contain no-repeat;
}

.filter-body {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
