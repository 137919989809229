@import '@sass';

main.inventory {
  width: 100%;
  display: flex;

  .filters {
    width: 25%;

    @include noDesktop {
      width: 0;
    }

    :global {
      @include desktop {
        .c-toggle {
          border-color: $color-gray;
        }
      }
    }
  }

  .main {
    width: 100%;
    padding: 24px;
    padding-bottom: 64px;

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
