.os-host-overflow {
  overflow: visible !important;
}

.os-scrollbar {
  .os-scrollbar-track {
    background: #5c5c5f !important;
  }

  .os-scrollbar-handle {
    background: $color-blue-electric !important;

    &:hover {
      cursor: grab;
    }

    &:active {
      background: rgb(6, 29, 45) !important;
      cursor: grabbing;
    }
  }
}

.os-scrollbar-vertical {
  width: 12px !important;
  right: -16px;
  margin-right: 0px !important;
  padding-left: 4px !important;
}

.os-scrollbar-horizontal {
  height: 8px !important;
  bottom: -14px;
  padding-top: 4px !important;
  padding-left: 0px !important;
}

.c-button {
  height: 36px;
  padding: 0 20px;
  padding-top: 2px;
  border-radius: 4px;
  font: 2rem 'Odibee';
  letter-spacing: 0.1rem;
  color: $color-white;
  border: 0;
  background-color: $color-blue;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  transition-duration: 0.2s;

  &:hover {
    background-color: $color-blue_hover;
    transition-duration: 0.1s;
  }
}

.nav-desktop {
  a:hover {
    color: $color-blue-electric;
    transition: color 0.35s;
  }
}

.nav-mobile {
  width: 72px;
  height: 72px;

  .toggle {
    input {
      z-index: #{$zindex-fixed};
    }

    span {
      z-index: #{$zindex-fixed - 1};
      background: $color-white;
    }

    input:checked ~ span {
      background: $color-white;
    }

    .links {
      z-index: #{$zindex-fixed - 2};
      border-right: 1px solid $color-blue-light;
      background: rgb(2, 41, 59);
      background: linear-gradient(
        0deg,
        rgba(1, 6, 20, 1) 0%,
        rgba(1, 14, 29, 1) 50%,
        rgba(1, 6, 20, 1) 100%
      );
    }

    .more {
      z-index: #{$zindex-fixed - 2};
    }

    .bg {
      z-index: #{$zindex-fixed - 3};
    }

    a {
      color: $color-white;

      &:hover {
        color: $color-blue-electric;
        transition: color 0.35s;
      }
    }
  }

  @include mobile {
    width: 60px;
    height: 60px;
  }
}

.c-scrollTop {
  width: 48px;
  height: 48px;
  right: 0;
  bottom: 0;
  border: 0;
  border-left: 1px solid $color-gray-dark;
  border-bottom: 0;
  border-radius: 0;
  background-color: $color-gray-dark;
  opacity: 0;
  transition: opacity 0.35s;

  &.visible {
    opacity: 1;
    transition: opacity 0.35s;
  }

  &:hover {
    background-color: $color-gray-medium;
    transition: background-color 0.35s;
  }

  .icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    background: url(/assets/images/shared/angle.svg) center/auto 12px no-repeat;
    filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
  }

  @include mobile {
    width: 40px;
    height: 40px;
  }
}

.c-modal {
  @media only screen and (max-width: 767px) {
    width: 100vw;
    height: var(--vh100);
  }
}

.c-modal-content {
  width: 60%;
  max-width: 100%;
  height: auto;
  padding: 0;
  background: transparent;

  @include noDesktop {
    width: 90%;
  }

  @include mobile {
    width: 100vw;
    height: var(--vh100);
  }
}

.c-modal-close {
  @extend .filter-electric-blue;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  border: 2px solid black;
  border-radius: 50%;
  background: url(/assets/images/shared/close.svg) center/14px 14px no-repeat;

  @include mobile {
    position: fixed;
    top: 8px;
    right: 8px;
  }
}

.c-modal-header {
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 20px;
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.c-modal-body {
  font-size: 2rem;
  line-height: 2.6rem;
  flex-grow: 3;

  @include mobile {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.c-modal-footer {
  margin-top: 48px;

  @include mobile {
    margin-top: 32px;
  }
}

.c-chip {
  background-color: $color-blue;
  text-transform: capitalize;
  transition: background-color 0.1s;

  span {
    transition: 0.2s;
  }

  .c-chip-action {
    right: 4px;
    transition: 0.2s;
  }

  &.c-chip-with-action:hover {
    background-color: $color-blue_hover;

    span {
      opacity: 0;
      transition: 0.2s;
    }

    .c-chip-action {
      right: calc(50% - 4px);
      transition: 0.2s;
    }
  }
}
