@import '@sass';

.container {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;

    .counter {
      display: flex;
      font-family: BigerOver;
      font-size: 3.2rem;
      line-height: 3.5rem;

      @include mobile {
        font-size: 2.2rem;
      }

      span {
        color: $color-gray-medium;
        padding-left: 12px;
      }
    }

    .selectors {
      display: flex;
    }
  }
}
