@import '@sass';

.container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 24px;
}

.social-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  place-content: center;
  position: relative;
  z-index: 1;
  transition: 0.5s linear;

  &:before {
    content: '';
    width: 28px;
    height: 28px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    border-radius: 50%;
    z-index: -1;
    transform: scale(0);
    transition: 0.3s cubic-bezier(0.95, 0.32, 0.37, 1.21);
  }

  &:hover img {
    transition: 0.5s linear;
    filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
  }

  &:hover::before {
    transform: scale(1);
  }

  &:nth-child(1):hover:before {
    background: #3b5999;
  }

  &:nth-child(2):hover:before {
    background: #00acee;
  }

  &:nth-child(3):hover:before {
    background: #0088cc;
  }
}
