@import '@sass';

.container {
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.login-button {
  height: 52px;
  margin-right: 12px;

  @include mobile {
    height: 40px;
  }
}

.logged {
  height: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #01dbfe;

  img {
    height: 24px;
  }

  @include mobile {
    border: 0;
    padding: 0 16px;
  }
}

.account {
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: Odibee;
  font-size: 1.6rem;

  .wallet {
    @include Odibee(1.6, 3);

    @include mobile {
      display: none;
    }
  }

  .balance {
    padding-left: 30px;
    @include Odibee(1.6, 3);

    img {
      height: 27px;
      margin-left: 10px;
      margin-bottom: 2px;
    }
  }

  @include mobile {
    display: none;
  }
}
