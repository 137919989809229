.container {
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  flex-direction: column;
}

.input-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.password {
  position: relative;
  display: flex;
}

.icon-password {
  position: absolute;
  display: flex;
  height: 100%;
  right: 10px;
  color: #01dbfe;
  justify-content: flex-end;
  align-items: center;
}

.label {
  padding-bottom: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  color: #ffffff;
}

.button-container {
  display: flex;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
