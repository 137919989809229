@import '@sass';

.container {
  width: 120px;
  height: 18px;
  position: relative;
  cursor: pointer;
}

.back-button {
  font-weight: 700;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #01dbfe;

  @include noDesktop {
    left: 0;
  }
}

.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 16px;
  background: url('/assets/images/shared/angle.svg') center/contain no-repeat;
  transform: rotate(-90deg);

  @include noDesktop {
    margin-right: 12px;
  }

  @include mobile {
    margin-right: 4px;
  }
}

.text {
  @include Montserrat(1.8, 1);
  display: inline-block;
}
