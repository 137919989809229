@import '@sass';

.container {
  display: flex;
  border: 1px solid;
  border-radius: 8px;

  @include noDesktop() {
    display: block;
  }

  .left {
    max-width: 300px;
    display: flex;
    position: relative;
    padding: 12px 24px;
    border-right: 1px solid #373943;
    background: rgba(1, 219, 254, 0.08);

    @include noDesktop() {
      max-width: 100%;
      border: 0;
      border-bottom: 1px solid #373943;
    }

    .image-wrapper {
      flex-grow: 3;

      .piece-image {
        height: 180px;
        padding: 8px 0;
        filter: drop-shadow(0px 4px 48px rgba(1, 219, 254, 0.2));
      }

      @include noDesktop() {
        text-align: center;
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include noDesktop() {
        height: calc(100% - 24px);
        position: absolute;
        top: 12px;
        right: 24px;
      }

      .top {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;

        .id {
          margin-top: 4px;
          font-family: Odibee;
          font-size: 1.4rem;
          color: $color-white;
          opacity: 0.6;
        }

        .antiquity {
          font-family: Odibee;
          font-size: 1.4rem;
          line-height: 1.6rem;
          opacity: 0.6;
        }
      }

      .price {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .right {
    position: relative;
    display: flex;
    flex-grow: 3;
    gap: 24px;
    padding: 12px 24px;

    @include mobile() {
      display: block;
    }

    .info {
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .name {
        font-family: Odibee;
        font-size: 2.2rem;
        line-height: 24px;
      }

      .data {
        display: flex;
        gap: 24px;

        .icons {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 12px;

          .edition {
            position: absolute;
            bottom: 12px;
            left: 24px;
            font-family: Odibee;
            opacity: 0.6;
            font-size: 1.5rem;
            font-weight: bold;
          }
        }

        .stats {
          flex-grow: 3;
        }
      }
    }

    .cards {
      width: 260px;
      display: flex;
      align-items: center;

      @include mobile() {
        width: 100%;
        margin-top: 24px;
        justify-content: center;
      }

      img {
        height: 180px;
      }
    }
  }
}
