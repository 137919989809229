.button {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

:global {
  .devTools-grid::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: var(--vh100);
    z-index: 3000000;
    opacity: 0.75;
    background-size: 4px 4px;
    background-position: 0px 0px;
    pointer-events: none;
    mix-blend-mode: difference;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 80%,
        #00ffff 93.75%,
        #00ffff 100%
      ),
      linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%, #00ffff 93.75%);
    background-repeat: repeat;
  }
}
