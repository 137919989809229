.scrollTop {
  width: 36px;
  height: 36px;
  position: fixed;
  right: 12px;
  bottom: -36px;
  z-index: 999;
  background-color: #666;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: bottom 0.35s;

  :global() {
    &.visible {
      bottom: 0;
      transition: bottom 0.35s;
    }
  }
}
