@import '@sass';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
}

.label {
  padding-bottom: 1rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  align-items: flex-start;
}

.input {
  width: 22rem;
  height: 4.2rem;

  background: #101421;
  border-color: transparent;
  border-radius: 0.4rem;

  padding-left: 1.2rem;
  padding-right: 1.2rem;

  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.notice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.4rem;
  height: 4.2rem;

  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;

  color: $color-gray-light;
}
