@import '@sass';

.container {
  width: 100%;
  height: 220px;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;

  @include mobile {
    height: 132px;
  }
}

.title-wrapper {
  width: 100%;
  position: absolute;
  bottom: 84px;

  @include mobile {
    top: 72px;
    bottom: auto;
  }

  h1 {
    font-family: BigerOver;
    font-size: 4rem;

    @include mobile {
      font-size: 2.7rem;
    }
  }
}

.sticky {
  position: fixed;
  top: -148px;
  z-index: $zindex-fixed;

  @include mobile {
    top: -72px;
    border-bottom: 1px solid $color-gray;
  }
}

.banner-marketplace {
  background-image: url('/assets/images/header/banner-marketplace.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-marketplace-sd.jpg');
  }
}

.banner-inventory {
  background-image: url('/assets/images/header/banner-inventory.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-marketplace-sd.jpg');
  }
}

.banner-users {
  background-image: url('/assets/images/header/banner-users.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-marketplace-sd.jpg');
  }
}

.banner-user {
  background-image: url('/assets/images/header/banner-users.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-marketplace-sd.jpg');
  }
}

.banner-activity {
  background-image: url('/assets/images/header/banner-activity.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-activity-sd.jpg');
  }
}

.banner-medamon {
  background-image: url('/assets/images/header/banner-activity.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-activity-sd.jpg');
  }
}

.banner-details {
  background-image: url('/assets/images/header/banner-details.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-details-sd.jpg');
  }
}

.banner-profile {
  background-image: url('/assets/images/header/banner-profile.jpg');

  @include mobile {
    background-image: url('/assets/images/header/banner-profile-sd.jpg');
  }
}
