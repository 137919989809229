@import '@sass';

html {
  font-size: (10 / $font-base-size * 100) * 1%;
}

body {
  margin: 0 auto;
  background-color: $color-background;
  font-family: $font-main;
  color: $color-white;
}

b {
  font-weight: bold;
}

main {
  padding: 0 0 60px;

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.filter-white {
  filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
}

.filter-gray {
  filter: invert(78%) sepia(0%) saturate(0%) hue-rotate(204deg) brightness(93%) contrast(89%);
}

.filter-electric-blue {
  filter: invert(62%) sepia(100%) saturate(2217%) hue-rotate(147deg) brightness(109%) contrast(99%);
}
