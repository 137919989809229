@import '@sass';

.container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  padding-top: 15px;
  // padding-bottom: 10px;

  font-family: Montserrat;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;

  &.left {
    width: 60%;
    font-family: BigerOver;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.5px;

    @include noDesktop {
      font-size: 1.4rem;
    }

    @include mobile {
      font-size: 1rem;

      img {
        width: 24px;
        height: 24px !important;
      }
    }
  }
  &.center {
    width: 10%;
    justify-content: center;
  }
  &.right {
    width: 30%;
    justify-content: flex-end;
  }
}
