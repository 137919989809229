/*
 CSS for the main interaction
*/
.tabset > input[type='radio'] {
  position: absolute;
  left: -200vw;
}

@import '@sass';

.tabset .tab-panel {
  display: none;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2) {
  display: block;
}

/*
   Styling
  */

.tabset > label {
  position: relative;
  display: inline-block;
  padding: 1.5rem 1.5rem 1.7rem;
  border: 0.1rem solid transparent;
  border-bottom: 0;
  font-family: BigerOver;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;

  color: #465158;
}

.tabset > label:hover,
.tabset > input:focus + label {
  color: #ffffff;
}

.tabset > input:checked + label {
  color: #ffffff;
  border-bottom: 0.3rem solid #01dbfe;
  margin-bottom: -1px;
}

.tab-panel {
  min-height: 240px;
  padding: 24px;

  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);

  border: 1px solid #01dbfe;
  box-sizing: border-box;
}
