@import '@sass';

.sitemap {
  display: flex;
  align-items: center;

  @include mobile {
    display: none;
    padding-right: 0;
  }
}

.ul {
  display: flex;
  justify-content: flex-end;
}

.li {
  padding-left: 25px;

  @include mobile {
    padding-left: 0;
    padding-right: 16px;
  }
}

.link {
  font-family: 'BigerOver';
  font-size: 1.2rem;
  text-align: center;
}
