.price {
  display: flex;
  font-family: Odibee;
  font-size: 1.8rem;
  line-height: 1.6rem;
  color: #01dbfe;

  img {
    height: 24px;
    margin-top: -5px;
    padding-left: 4px;
  }
}
