@import '@sass';

.list {
  width: 100%;
  // padding: 20px;
  // background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
  // border: 2px solid $color-blue-electric;
  // border-radius: 4px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(MIN(100%, 240px), 1fr));
  gap: 20px;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
