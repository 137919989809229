.table-container {
  display: flex;
  flex-direction: column;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #01dbfe;
  background: rgba(1, 219, 254, 0.1);
  border-radius: 4px;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 2rem;
  color: #ffffff;
  list-style-type: none;

  font-family: BigerOver;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 1rem;

  line-height: 1.5rem;

  li {
    // padding: 0.2rem;
    padding-left: 0.5rem;
    &:hover {
      color: #01dbfe;
    }
  }
}

.tr {
  border-radius: 0.4rem;
  padding: 0.35em;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.7rem;

  letter-spacing: 0.1rem;
  text-transform: uppercase;

  color: #ffffff;
}

.th,
.td {
  padding: 0.625em;
  text-align: center;
  border-left: 2px solid #01dbfe;
  border-right: 2px solid #01dbfe;
  a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.7rem;

    letter-spacing: 0.1rem;
    text-transform: uppercase;

    color: #ffffff;
  }
}

.thead {
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
}

.tbody {
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);
}

.th {
  border: 2px solid #01dbfe;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  font-size: 1.6rem;
  font-family: BigerOver;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  color: #ffffff;
}

.withIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  .value {
    width: 80%;
    height: 100%;
  }

  .icon {
    width: 20%;
    height: 100%;

    img {
      width: 32px;
    }
  }
}

tr:nth-child(even) {
  background: rgba(1, 219, 254, 0.1);
  border-radius: 0.4rem;
}

@media screen and (max-width: 60rem) {
  .table {
    border: 0;
  }

  .thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.1rem;
  }

  .tr {
    display: block;
    margin-bottom: 0.625em;
  }

  .td {
    border-bottom: 0.1rem solid #01dbfe;
    border-left: 0.5rem solid #01dbfe;
    border-right: none;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .td:last-child {
    border-bottom: 0;
  }
}
