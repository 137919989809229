@import '@sass';

.container {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #ffffff;
  user-select: none;
}

.input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex-direction: column;
    gap: 8px;

    button {
      width: 100%;
      margin: 0 !important;
    }
  }
}

.button {
  margin-left: 20px;
}
