@import '@sass';

main.inventory {
  display: flex;
}

.filters {
  width: 25%;

  @include noDesktop {
    width: 0;
  }
}

.main {
  width: 100%;
  padding: 24px;

  @include mobile {
    padding: 24px 0;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-bottom: 16px;
  }
}
