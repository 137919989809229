@import '@sass';

.container {
  padding: 28px 60px 0;
  position: relative;

  .bg {
    content: '';
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    background-position: left top;
    position: fixed;
    top: 10vh;
    left: 20%;
    z-index: -1;
    opacity: 0.06;
    filter: contrast(2) saturate(2) blur(2px);

    @include portrait() {
      top: 20vh;
      left: 0;
      background-size: 150% auto;
    }

    @include mobile {
      display: none;
    }

    &.antrox {
      top: 20vh;
    }

    &.donnardo {
      left: 0%;

      @include portrait() {
        background-size: 120% auto;
      }
    }

    &.inferno {
      left: 30%;
      background-size: 80% auto;

      @include portrait() {
        top: 20vh;
        left: 20%;
        background-size: 120% auto;
      }
    }

    &.colossus {
      left: 30%;
      background-size: 80% auto;

      @include portrait() {
        top: 20vh;
        left: 20%;
        background-size: 120% auto;
      }
    }

    &.jetto {
      left: 30%;
      background-size: 80% auto;

      @include portrait() {
        top: 10vh;
        left: 20%;
        background-size: 150% auto;
      }
    }

    &.leppux {
      @include portrait() {
        top: 20vh;
        left: 0;
        background-size: 130% auto;
      }
    }

    &.mikazuki {
      left: 50%;
      background-size: 50% auto;

      @include portrait() {
        top: 20vh;
        left: 30%;
        background-size: 100% auto;
      }
    }

    &.olympus {
      left: 30%;
      background-size: 80% auto;

      @include portrait() {
        top: 20vh;
        left: 20%;
        background-size: 120% auto;
      }
    }

    &.phalco {
      top: 20vh;
    }

    &.saura {
      top: 20vh;
    }

    &.qwerty {
      top: 20%;
      left: 30%;
      background-size: 90% auto;

      @include portrait() {
        top: 20vh;
        left: 10%;
        background-size: 150% auto;
      }
    }

    &.sanctus {
      @include portrait() {
        top: 20vh;
        left: 30%;
        background-size: 150% auto;
      }
    }

    &.shinobi {
      @include portrait() {
        background-size: 130% auto;
      }
    }

    &.subotai {
      background-size: 60% auto;
      left: 40%;
      filter: contrast(2) saturate(1) blur(2px);

      @include portrait() {
        top: 20vh;
        left: 20%;
        background-size: 120% auto;
      }
    }

    &.tweezers {
      top: 20%;
    }
    &.w4sabi {
      top: 20%;
    }
  }

  @include noDesktop {
    padding: 20px 24px 60px;
  }

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }

  :global {
    .c-back-button {
      margin-left: -32px;

      @include mobile {
        margin-left: 0px;
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .info {
    font-family: BigerOver;

    .id {
      font-size: 3.2rem;
      color: $color-gray-light;

      span {
        color: #ffffff;
      }

      @include noDesktop {
        font-size: 2.4rem;
      }
    }

    .antiquity {
      margin-top: 12px;
      text-transform: capitalize;
      font-family: Odibee;
      font-size: 2.4rem;
      color: $color-gray-light;

      @include noDesktop {
        font-size: 1.6rem;
      }
    }
  }

  .buttons-container {
    display: flex;
    gap: 12px;

    @include noDesktop {
      display: none;
    }
  }

  .transfer-button {
    display: flex;
    margin-right: 20px;
  }
}

.details {
  height: 360px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-top: 60px;
  padding-left: 32px;
  border: 2px solid #01dbfe;
  border-radius: 4px;
  background: linear-gradient(105.09deg, rgba(1, 219, 254, 0.2) -5.77%, rgba(9, 44, 76, 0) 105.57%);

  @include noDesktop {
    height: auto;
    margin-top: 24px;
    padding: 0;
    border: 0;
    background: none;
    display: block;
  }

  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 2;

    @include noDesktop {
      width: 100%;
      display: block;
      margin-top: 24px;
    }

    .type {
      font-family: 'BigerOver';
      font-size: 2.4rem;

      @include noDesktop {
        text-align: center;
        margin-top: 12px;
      }

      @include mobile {
        font-size: 1.6rem;
      }
    }

    .owner {
      height: 25px;
      display: flex;
      align-items: center;
      align-content: center;
      gap: 8px;
      margin-top: 24px;
      font-size: 1.6rem;

      @include noDesktop {
        justify-content: center;
      }

      .name {
        font-family: 'BigerOver';
      }

      .exName {
        text-decoration-line: underline;
        color: $color-blue-electric;
      }

      .exName-value {
        font-size: 1.2rem;

        @include noDesktop {
          display: none;
        }
      }
    }

    .characteristics {
      width: 100%;
      margin-top: 24px;
      padding-left: 24px;
      display: flex;
      gap: 20px;

      @include noDesktop {
        display: block;
        padding-left: 0px;

        &:before {
          content: '';
          width: 100%;
          height: 8px;
          background: url(../../assets/images/shared/line.png) center/contain no-repeat;
          display: block;
          margin-bottom: 24px;
        }
      }

      .family {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        margin-right: 40px;

        @include noDesktop {
          flex-direction: row;
          justify-content: center;
          gap: 32px;
          margin: 0;
        }
      }

      .stats {
        flex-grow: 4;

        @include noDesktop {
          margin-top: 24px;
          text-align: center;
        }
      }
    }
  }
}

.history {
  margin: 80px 0;

  @include noDesktop {
    margin: 40px 0;

    &:before {
      content: '';
      width: 100%;
      height: 8px;
      background: url(../../assets/images/shared/line.png) center/contain no-repeat;
      display: block;
      margin-bottom: 24px;
    }
  }

  .title {
    margin-bottom: 4px;
    font-family: Odibee;
    font-size: 3.2rem;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 3px;
    color: $color-blue-electric;
  }
}

.mobile-buttons {
  max-width: 212px;
  margin: 24px auto 0 auto;
  display: flex;
  flex-direction: column;
  place-content: center;
  gap: 12px;

  @include desktop {
    display: none;
  }
}
